









































import ContentCard from "@/components/ContentCard.vue";
import Table from "@/components/Table.vue";
import { useGetRolesQuery } from "@/graphql/types";
import { TableField } from "@/types/TableField";
import { useResult } from "@vue/apollo-composable";
import { computed, defineComponent, provide } from "@vue/composition-api";
import useProfile from "@/composables/useProfile";
import useNavigateToRole from "@/composables/navigation/useNavigateToRole";
import { useNumberFormat } from "@/composables/useNumberFormat";
import { ParentQueryKey } from "./symbols";

export default defineComponent({
  components: {
    ContentCard,
    Table,
  },
  setup(_, { root }) {
    const { isCentralAdmin } = useProfile();
    const { result, loading, error, document, variables } = useGetRolesQuery();
    provide(ParentQueryKey, {
      query: document.value,
      variables: variables.value,
    });

    return {
      editable: computed(() => isCentralAdmin.value),
      loading,
      error,
      useNavigateToRole,
      items: useResult(result, [], (data) => data.roles.nodes),
      totalCount: useResult(result, undefined, (data) => data.roles.totalCount),
      fields: computed<TableField[]>(() => [
        {
          key: "name",
          label: root.$tc("roles.fields.name"),
          sortable: true,
          filter: true,
        },
        {
          key: "attribute",
          label: root.$tc("roles.fields.attribute"),
          sortable: true,
          filter: true,
        },
        {
          key: "application.name",
          label: root.$tc("roles.fields.application"),
          sortable: true,
          filter: true,
        },
        {
          key: "assignedUsers.totalCount",
          label: root.$tc("roles.fields.users_count"),
          sortable: true,
          filter: false,
          width: "15%",
        },
      ]),
      useNumberFormat,
    };
  },
});
